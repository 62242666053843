import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
// import VueGtag from "vue-gtag";


Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "홈",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/HomeView.vue"),
    meta: {},
  },
  {
    path: "/login",
    name: "로그인",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    meta: {},
  },
  {
    path: "/join",
    name: "회원가입",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/JoinView.vue"),
    meta: {},
  },
  {
    path: "/findpw",
    name: "비밀번호 찾기",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/FindPw.vue"),
    meta: {},
  },
  {
    path: "/grlook",
    name: "그룹 등록 및 조회",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/GroupView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pplook",
    name: "여권 등록 및 조회",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PassportView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ppdetail",
    name: "여권정보 상세 조회",
    component: () =>
      import(/* webpackChunkName: "login" */ "../components/PPDetailView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/excel",
    name: "엑셀 서식 관리",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ExcelView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/exceldownload",
    name: "엑셀 다운로드",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/DownloadView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pointcharge",
    name: "전자결제",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PointChargeView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/chargelog",
    name: "결제내역",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ChargeLogView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/uselog",
    name: "사용내역",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PointUseView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/myinfo",
    name: "내 정보",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/MyInfoView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/noticeboard",
    name: "게시판",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Board.vue"),
    meta: {},
  },
  {
    path: "/noticecontent",
    name: "게시판",
    component: () =>
      import(/* webpackChunkName: "login" */ "../components/BoardContent.vue"),
    meta: {},
  },
  {
    path: "/manage/point",
    name: "관리자 - 결제관리",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminChargeLog.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/refund",
    name: "관리자 - 결제관리(환불)",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminRefund.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/pointuse",
    name: "관리자 - 결제관리(기간단위)",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminPointDaily.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/member",
    name: "관리자 - 회원관리",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminMember.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/member/info",
    name: "관리자 - 회원사 상세",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../components/AdminMemberInfo.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/scan",
    name: "관리자 - 여권스캔 관리",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminScanManage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/grlook",
    name: "관리자 - 여권스캔 관리(그룹)",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminGroup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/pplook",
    name: "관리자 - 여권스캔 관리(여권)",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminPassport.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/ppdetail",
    name: "관리자 - 여권 상세조회",
    component: () =>
      import(/* webpackChunkName: "login" */ "../components/AdminPPDetailView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/uselog",
    name: "관리자 - 사용내역 관리",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminUseLog.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/board",
    name: "관리자 - 게시판 관리",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AdminBoard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage/noticecontent",
    name: "게시판",
    component: () =>
      import(/* webpackChunkName: "login" */ "../components/AdminBoardContent.vue"),
    meta: {},
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  const isAuthenticated = sessionStorage.getItem("memberLogin");
  // console.log(isAuthenticated)
  if (requiresAuth && isAuthenticated == "0") {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    // console.log("go")
    next();
  }
});

// router.afterEach((to, from, next) => {
//   if (
//     to.path === "/" ||
//     to.path === "/grlook" ||
//     to.path === "/pplook" ||
//     to.path === "/pointcharge"
//   ) {
//     let label;
//     switch (to.path) {
//       case "/":
//         label = "메인페이지"; // "/" 경로의 라벨을 "Home Page"로 지정
//         break;
//       case "/grlook":
//         label = "그룹등록 및 조회"; // "/grlook" 경로의 라벨을 지정
//         break;
//       case "/pplook":
//         label = "여권등록 및 조회"; // "/pplook" 경로의 라벨을 지정
//         break;
//       case "/pointcharge":
//         label = "전자결제"; // "/pointcharge" 경로의 라벨을 지정
//         break;
//     }
//     router.app.$gtag.event("page_view", {
//       event_category: "navigation",
//       event_label: label, // 각 경로에 맞는 라벨 사용
//     });
//   }
//   next();
// });

export default router;
